<template>
  <section class="location">
    <div class="location__wrap inner">
      <h3 class="treatment__tt slidetop" v-animate-onscroll="'active'"  v-html="location.title"></h3>
      <ul class="location__list">
        <li class="scaleFade" v-for="item in location.list" v-animate-onscroll="'active'" :key="item.title">
          <figure><img :src="item.thumb" alt=""/></figure>
          <strong>{{item.title}}</strong>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    location:{
        type: Object,
        required: true
    }
  }
}
</script>

<style>

</style>