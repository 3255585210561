<template>
  <section class="prospect">
    <div class="prospect__imgs slidetop" v-animate-onscroll="'active'">
      <figure><img :src="prospect.thumb" alt=""/></figure>
    </div>
    <div class="prospect__info slidetop detay1" v-animate-onscroll="'active'">
      <strong>{{prospect.title}}</strong>
      <p v-html="prospect.description"></p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
      prospect:{
          type: Object,
          required: true
      }
  },
}
</script>

<style>

</style>