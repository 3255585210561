<template>
  <section class="treatment inner">
    <h3 class="treatment__tt slidetop" v-animate-onscroll="'active'" v-html="treatment.title"></h3>
    <div class="treatment__wrap">
      <div class="treatment__row">
        <div class="treatment__list">
          <div class="treatment__info slidetop" v-animate-onscroll="'active'" v-for="(item, index) in treatment.list" :key="index">
            <template v-if="index < 4">
              <figure><img :src="item.thumb" alt=""/></figure>
              <strong>{{item.title}}</strong>
              <p>{{item.description}}</p>
            </template>
          </div>
        </div>
        <div class="treatment__right slidetop detay1" v-animate-onscroll="'active'">
          <div class="treatment__info">
            <figure><img :src="treatment.list[4].thumb" alt=""/></figure>
            <strong>{{treatment.list[4].title}}</strong>
            <p>{{treatment.list[4].description}}</p>
          </div>
        </div>
      </div>
      <div class="treatment__down">
        <div class="treatment__info slidetop" v-animate-onscroll="'active'">
          <figure><img :src="treatment.list[5].thumb" alt=""/></figure>
          <strong>{{treatment.list[5].title}}</strong>
          <p>{{treatment.list[5].description}}</p>
        </div>
        <div class="treatment__info slidetop detay1" v-animate-onscroll="'active'">
          <figure><img :src="treatment.list[6].thumb" alt=""/></figure>
          <strong>{{treatment.list[6].title}}</strong>
          <p>{{treatment.list[6].description}}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    treatment:{
        type: Object,
        required: true
    }
  }
}
</script>

<style>

</style>