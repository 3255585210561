<template>
  <section class="idea__slogs" :style="{ backgroundImage: `url(${bgs})` }">
    <div class="idea__slogs--wrap">
      <div class="slogo__top slidetop" v-animate-onscroll="'active'">
        <span></span>
        <strong>{{slog.title}}</strong>
      </div>
      <div class="idea__slogo--in slidetop detay1" v-animate-onscroll="'active'" v-html="slog.description">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data(){
    return {
      bgs: require('@/assets/images/introduct.jpg')
    }
  },
  props: {
    slog:{
        type: Object,
        required: true
    }
  }
}
</script>

<style>

</style>