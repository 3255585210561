<template>
  <section class="welcomes"  :style="{ backgroundImage: `url(${join.thumb})` }">
    <div class="welcome__wrap inner">
      <strong class="slidetop" v-animate-onscroll="'active'">{{join.title}}</strong>
      <div class="welcome__info slidetop detay1" v-animate-onscroll="'active'">
        <p v-html="join.description"></p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    join:{
        type: Object,
        required: true
    }
  }
}
</script>

<style>

</style>