<template>
  <section class="idea__banner"  v-if="introduct">
    <figure><img :src="introduct.thumb" alt=""/></figure>
    <div class="idea__banner--info">
      <div class="idea__tits">
        <strong>{{introduct.description}}</strong>
      </div>
      <div class="idea__btns">
        <div class="idea__btns--item" v-for="item in introduct.child" :key="item.catid">
          <router-link :to="`/concept${item.path}`">
            <strong>{{item.name}}</strong>
            <small>{{item.ename}}</small>
          </router-link>
        </div>
      </div>
    </div>
    <div class="idea__arrow"><i class="iconfont">&#xeb10;</i></div>
  </section>
</template>

<script>
export default {
  props: {
      introduct:{
          type: Object,
          required: true
      }
  },
}
</script>

<style>

</style>