<template>
  <section class="employee">
    <div class="employee__wrap inner">
      <div class="employee__top">
        <h3 class="treatment__tt slidetop" v-animate-onscroll="'active'">{{employee.title}}</h3>
        <p class="slidetot detay1" v-animate-onscroll="'active'">四大序列“横纵”结合，助你“纵横”职场</p>
      </div>
      <ul class="employee__list slideList" v-animate-onscroll="'active'">
        <li v-for="(item, index) in employee.list" :key="index">
          <figure><img :src="item.thumb" alt=""/></figure>
          <strong>{{item.title}}</strong>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    employee:{
        type: Object,
        required: true
    }
  }
}
</script>

<style>

</style>