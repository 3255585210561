<template>
  <section class="container">
    <Introduct :introduct='introductData'/>
    <Slog :slog="slog"/>
    <Prospect :prospect="prospect"/>
    <Treatment :treatment="treatment"/>
    <Location :location="location"/>
    <Employee :employee="employee"/>
    <Join :join="join"/>
  </section>
</template>

<script>
import Introduct from '../components/Idea/Introduct.vue';
import Slog from '../components/Idea/Slog.vue';
import Prospect from '../components/Idea/Prospect.vue';
import Treatment from '../components/Idea/Treatment.vue';
import Location from '../components/Idea/Location.vue';
import Employee from '../components/Idea/Employee.vue';
import Join from '../components/Idea/Join.vue';
export default {
  data() {
    return {
      ideaData: {},
      introductData: {},
      slog: {},
      prospect: {},
      treatment: {},
      location: {},
      employee: {},
      join: {}
    }
  },
  components: {
      Introduct,
      Slog,
      Prospect,
      Treatment,
      Location,
      Employee,
      Join
  },
  methods: {
    async geRecuiteData(){
      const {data: res} = await this.$http.get("getConcept");  
      this.ideaData = res.data
      this.introductData =  res.data.catinfo
      this.slog = res.data.page1
      this.prospect = res.data.page2
      this.treatment = res.data.page3
      this.location = res.data.page4
      this.employee = res.data.page5
      this.join = res.data.page6
      console.log( this.join)
    }
  },
  mounted() {
    this.geRecuiteData();
  }
}
</script>

<style>

</style>